<template>
    <div class="soft-wrap">
        <el-scrollbar class="main-content">
            <div class="top-box">
                <div class="top-left">
                    <div class="head-wrap">
                        <div class="head-content">
                            <div class="item-title">性别</div>
                            <div class="chat-box">
                            <span class="chat-content">
                                <span>您的店铺粉丝</span>
                                <span class="red-text" v-if="softInfo.man_ratio == softInfo.woman_ratio">男女比例一样</span>
                                <span class="red-text" v-if="softInfo.man_ratio > softInfo.woman_ratio">男性客户最多</span>
                                <span class="red-text" v-if="softInfo.man_ratio < softInfo.woman_ratio">女性客户最多</span>
                            </span>
                                <div class="angleLeft">
                                    <span class="angleContent"></span>
                                </div>
                            </div>
                        </div>
                        <div class="item-content">
                            <div id="genderCondition" style="width: 100%;height:100%;position: relative"></div>
                        </div>
                    </div>
                </div>
                <div class="top-right">
                    <div class="head-wrap">
                        <div class="head-content">
                            <div class="item-title">年龄</div>
                            <div class="chat-box">
                            <span class="chat-content">
                                <span>您的店铺粉丝主要集中在</span>
                                <span class="red-text">{{maxAge}}</span>
                            </span>
                                <div class="angleLeft">
                                    <span class="angleContent"></span>
                                </div>
                            </div>
                        </div>
                        <div class="item-content">
                            <div id="ageCondition" style="width: 100%;height:100%;position: relative"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-box">
                <div class="head-wrap">
                    <div class="head-content">
                        <div class="item-title">地区</div>
                        <div class="chat-box">
                            <span class="chat-content">
                                <span>您的店铺粉丝主要集中在</span>
                                <span class="red-text">{{maxArea}}等地</span>
                            </span>
                            <div class="angleLeft">
                                <span class="angleContent"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-content">
                    <el-table :data="areaList" class="customTable" style="width: 100%; flex: 1;"
                              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                        <el-table-column prop="rank" label="排名" align="center" width="100">
                            <template slot-scope="scope">
                                <span>{{scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="province" label="省份" align="center"></el-table-column>
                        <el-table-column prop="rank" label="成交占比" align="left">
                            <template slot-scope="scope">
                                <div class="deal-box">
                                    <span>{{scope.row.ratio}}%</span>
                                    <el-progress :percentage="scope.row.ratio" :show-text=false></el-progress>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="table-box">
                <div class="head-wrap">
                    <div class="head-content">
                        <div class="item-title">款式偏好</div>
                        <div class="chat-box">
                            <span class="chat-content">
                                <span>您的店铺款式主要是</span>
                                <span class="red-text">{{maxStyle}}等</span>
                            </span>
                            <div class="angleLeft">
                                <span class="angleContent"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-content">
                    <el-table :data="styleList" class="customTable" style="width: 100%; flex: 1;"
                              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                        <el-table-column prop="rank" label="排名" align="center" width="100">
                            <template slot-scope="scope">
                                <span>{{scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="style" label="款式" align="center"></el-table-column>
                        <el-table-column prop="rank" label="成交占比" align="left">
                            <template slot-scope="scope">
                                <div class="deal-box">
                                    <span>{{scope.row.ratio}}%</span>
                                    <el-progress :percentage="scope.row.ratio" :show-text=false></el-progress>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import echarts from "echarts";
    import {stuOpDataAnalysis} from '@/utils/apis'

    export default {
        name: "dataAnalysis",
        data() {
            return {
                areaList: [],
                styleList: [],
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                softInfo: {},
                maxAge: '',
                maxArea: '',
                maxStyle: '',
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId
                }
                stuOpDataAnalysis(param).then(res => {
                    if (res.data !== null) {
                        this.softInfo = res.data;
                        this.areaList = res.data.region_distribution.map(item => {
                            item.ratio = Number(item.ratio);
                            return item;
                        });
                        this.styleList = res.data.style_preference.map(item => {
                            item.ratio = Number(item.ratio);
                            return item;
                        });
                        this.formatData();
                        this.getGenderCondition();
                        this.getAgeCondition();
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            formatData() {
                let arr = [];
                let arr1 = [];
                for (let i = 0; i < 3; i++) {
                    arr.push(this.areaList[i].province);
                    arr1.push(this.styleList[i].style);
                }
                this.maxArea = arr.join('、')
                this.maxStyle = arr1.join('、')
            },
            getGenderCondition() {
                this.$nextTick(() => {
                    let genderCondition = this.$echarts.init(document.getElementById('genderCondition'));
                    let softInfo = this.softInfo;
                    genderCondition.setOption({
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
                            bottom: 0,
                            itemWidth: 18,  //图例标记的图形宽度
                            itemHeight: 18, //图例标记的图形高度
                            textStyle: {
                                color: '#000',
                                fontSize: 14,
                                lineHeight: 22
                            },
                            itemGap: 40,
                        },
                        series: [
                            {
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: ['55%', '70%'],
                                avoidLabelOverlap: false,
                                clockwise: false,
                                label: {
                                    show: false,
                                    position: 'center',
                                    // formatter: function (argument) {
                                    //     let html;
                                    //     html = '理论题:' + userInfo.essay_score + '\n\n' + '实操题:' + userInfo.operation_score;
                                    //     return html;
                                    // },
                                    textStyle: {
                                        fontSize: 15,
                                        color: '#999999'

                                    },
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                        fontSize: '40',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: true
                                },
                                data: [
                                    {
                                        name: '男性',
                                        value: softInfo.man_ratio,
                                        itemStyle: {
                                            normal: {
                                                color: { // 完成的圆环的颜色
                                                    colorStops: [{
                                                        offset: 0,
                                                        color: '#BEE7D0' // 0% 处的颜色
                                                    }, {
                                                        offset: 1,
                                                        color: '#2DC079' // 100% 处的颜色
                                                    }]
                                                }
                                            }
                                        },
                                    },
                                    {
                                        value: softInfo.woman_ratio,
                                        name: '女性',
                                        itemStyle: {
                                            normal: {
                                                color: { // 完成的圆环的颜色
                                                    colorStops: [{
                                                        offset: 0,
                                                        color: '#FDAE95' // 0% 处的颜色
                                                    }, {
                                                        offset: 1,
                                                        color: '#F9727E' // 100% 处的颜色
                                                    }]
                                                }
                                            }
                                        },
                                    },
                                ]
                            }
                        ]
                    })
                })
            },
            getAgeCondition() {
                let dataX = this.softInfo.data_analysis_age.x;
                let dataY = this.softInfo.data_analysis_age.y.map(item => {
                    return Number(item);
                });
                let max = Math.max(...dataY);
                let key = dataY.indexOf(max);
                this.maxAge = dataX[key]
                this.$nextTick(() => {
                    let ageCondition = this.$echarts.init(document.getElementById('ageCondition'));
                    ageCondition.setOption({
                        title: {
                            x: 'center',
                            y: 'bottom',
                            textStyle: { //设置主标题风格
                                Color: '#333333',//设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: 'normal',
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            padding: 0,
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    type: 'solid',
                                    width: 1,
                                    color: '#e0e2eb'
                                }
                            },
                        },
                        legend: {
                            show: true,
                            selectedMode: 'multiple',   //设置显示单一图例的图形，点击可切换
                            bottom: 0,
                            left: 500,
                            textStyle: {
                                color: '#666',
                                fontSize: 12
                            },
                            itemGap: 20,
                            data: ['分数'],
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            top: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: dataX,
                                boundaryGap: true,
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    interval: 0,
                                },
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '分数',
                                max: max + 10,
                                interval: 10,
                                axisLine: {
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    formatter: '{value}' + '%'
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: ['#F3F6FA'],
                                        width: 1,
                                        type: 'solid'
                                    }
                                }
                            },
                        ],
                        series: [
                            {
                                name: '占比',
                                type: 'bar',
                                barWidth: '18px',
                                data: dataY,
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            {offset: 0, color: '#60C5FF'},
                                            {offset: 1, color: '#428AF5'}
                                        ]
                                    ),
                                },
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'top',
                                        formatter: function (param) {
                                            return param.data + '%'
                                        }
                                    }
                                },
                            },
                        ]
                    })
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .soft-wrap {
        margin-top: 18px;
        height: calc(100% - 62px);
        display: flex;
        flex-direction: column;
        padding: 0 20px 44px;

        .main-content {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 0 20px;
                }
            }
        }

        .chat-box {
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #DCDCDC;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 10px;

            .chat-content {
                margin: 0 10px;

                .red-text {
                    color: #FF0000;
                }
            }

            .angleLeft {
                position: absolute;
                border: 6px solid transparent;
                top: 18px;
                margin-top: -8px;
                left: -14px;
                border-right: 8px solid #ededed;
            }

            .angleContent {
                position: absolute;
                border: 4px solid transparent;
                top: 50%;
                margin-top: -4px;
                z-index: 99;
                left: -1.5px;
                border-right: 6px solid #ffffff;
            }
        }

        .item-title {
            font-size: 18px;
            position: relative;

            &:before {
                content: '';
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                position: absolute;
                left: -13px;
                top: 3px;
            }
        }

        .head-wrap {
            padding: 34px 100px 26px 44px;

            .head-content {
                display: flex;
                align-items: center;
            }

            .item-content {
                margin-top: 24px;
            }
        }

        .top-box {
            display: flex;

            .top-left {
                border-radius: 8px;
                border: 1px solid #eeeeee;

                .item-content {
                    width: 260px;
                    height: 260px;
                }
            }

            .top-right {
                margin-left: 34px;
                border-radius: 8px;
                border: 1px solid #eeeeee;
                flex: 1;

                .item-content {
                    width: 100%;
                    height: 260px;
                }
            }
        }

        .table-box {
            border-radius: 8px;
            border: 1px solid #eeeeee;
            margin-top: 24px;

            .item-content {
                padding: 0 28px 40px;
            }

            .deal-box {
                display: flex;
                align-items: center;

                .el-progress {
                    width: 220px;
                    margin-left: 15px;
                }
            }
        }
    }
</style>